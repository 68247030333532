import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";

import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { validateEmail } from "~/helper/misc-functions";
import constants from "~/services/constants";
import * as locketService from "~/services/locketService";
import "./LoginModal.scss";

const LoginForm = ({ handleAfterLogin, onPleaseWait, ...props }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");

    const handleLogin = async () => {
        toast.dismiss();
        const toastId = toast.info("Logging in ....", {
            ...constants.toastSettings,
        });

        setIsDisabledSubmit(true);

        const res = await locketService.login(email, password, onPleaseWait);

        if (res) {
            handleAfterLogin(res);
        } else if (toast.isActive(toastId)) {
            toast.update(toastId, {
                ...constants.toastSettings,
                render: "Username or password is incorrect",
                type: "error",
            });
        } else {
            toast.dismiss();
            toast.error("Username or password is incorrect", {
                ...constants.toastSettings,
            });
        }
        setIsDisabledSubmit(false);
    };

    const handleEnterOnInput = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value && !validateEmail(e.target.value)) {
            setEmailErrorMessage("Invalid email");
            setIsDisabledSubmit(true);
        } else {
            setEmailErrorMessage("");
            setIsDisabledSubmit(false);
        }
    };

    return (
        <>
            <Form
                autoComplete="off"
                style={{
                    width: 250,
                }}
            >
                <Form.Group controlId="formBasicEmail">
                    <Form.Label className="custom-label">
                        {emailErrorMessage ? (
                            <span style={{ color: "red" }}>
                                {emailErrorMessage}
                            </span>
                        ) : (
                            "Email"
                        )}
                    </Form.Label>
                    <Form.Control
                        style={{
                            height: 40,
                        }}
                        className={"bs-input"}
                        type="text"
                        placeholder="Enter email"
                        value={email}
                        onChange={handleChangeEmail}
                        autoComplete="none"
                        onKeyDown={handleEnterOnInput}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mt-3">
                    <Form.Label className="custom-label">Password</Form.Label>
                    <div className="password-wrapper">
                        <Form.Control
                            style={{
                                height: 40,
                            }}
                            type={"text"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                            className={"bs-input"}
                            onKeyDown={handleEnterOnInput}
                        />
                    </div>
                </Form.Group>
            </Form>

            <Button
                style={{
                    width: 250,
                    marginTop: 20,
                }}
                variant="primary"
                onClick={handleLogin}
                className="btn-login"
                disabled={!email || !password || isDisabledSubmit}
            >
                Login
            </Button>
        </>
    );
};

LoginForm.propTypes = {
    handleAfterLogin: PropTypes.func,
    onHide: PropTypes.func,
    onPleaseWait: PropTypes.func,
};

export default LoginForm;
