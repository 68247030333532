import classNames from "classnames/bind";
import { useContext } from "react";
import styles from "./Header.module.scss";

import images from "~/assets/images";
import { AuthContext } from "~/contexts/AuthContext";
const cx = classNames.bind(styles);

const Header = () => {
    const { user } = useContext(AuthContext);

    return (
        <div className={cx("wrapper")}>
            <div className={cx("logo-wrapper")}>
                <img src={images.logo} alt="logo" className={cx("logo")} />
                <h1>Locket Gold</h1>
            </div>
        </div>
    );
};

export default Header;
