import TippyHeadless from "@tippyjs/react/headless";
import classNames from "classnames/bind";
import styles from "./Help.module.scss";

const cx = classNames.bind(styles);

const Help = () => (
    <div>
        <TippyHeadless
            render={(attrs) => (
                <div className={cx("wrapper")} tabIndex="-1" {...attrs}>
                    <p className={cx("content")}>
                        Sever cùi nên giới hạn file nhé các bạn ơiii ảnh{" "}
                        <b> 5MB </b> video ít hơn <b>50 MB</b> {""}
                        <b>
                            2&nbsp;phút.
                            <br />
                        </b>{" "}
                        <a
                            className={cx("link")}
                            href="https://tinypng.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            trang này
                        </a>
                        <span> để nén PNG và JPEG files.</span>
                        <br />
                        <span>
                            hoặc{" "}
                            <a
                                className={cx("link")}
                                href="https://www.freeconvert.com/video-compressor"
                                target="_blank"
                                rel="noreferrer"
                            >
                                trang này
                            </a>{" "}
                            để nén ảnh hoặc video.
                        </span>
                    </p>
                </div>
            )}
            delay={[100, 500]}
            // offset={[25, 10]}
            placement="bottom-end"
        >
            <div className={cx("help-me")}>Help me</div>
        </TippyHeadless>
    </div>
);

export default Help;
