import TippyHeadless from "@tippyjs/react/headless";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import { useContext } from "react";
import styles from "./UserDropdown.module.scss";

import { AuthContext } from "~/contexts/AuthContext";
import { deleteCookie } from "~/helper/misc-functions";
const cx = classNames.bind(styles);

const UserDropdown = ({ userInfo, className }) => {
    const { setUser } = useContext(AuthContext);

    const handleLogout = () => {
        deleteCookie("user");
        setUser(null);
    };

    return (
        <div className={cx("wrapper", className)}>
            <TippyHeadless
                render={(attrs) => (
                    <div className={cx("dropdown")} tabIndex="-1" {...attrs}>
                        <div className={cx("dropdown-item")}>
                            <button onClick={handleLogout}>Đăng xuất</button>
                        </div>
                    </div>
                )}
                interactive
                placement="bottom-end"
                delay={[100, 500]}
                hideOnClick={false}
            >
                <p>
                    Hi,{" "}
                    <b className={cx("display-name")}>
                        {userInfo?.displayName}
                    </b>
                </p>
            </TippyHeadless>
        </div>
    );
};

UserDropdown.propTypes = {
    userInfo: PropTypes.object,
    className: PropTypes.string,
};

export default UserDropdown;
